import './Navigation.scss';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import logo from '../assets/images/iconlogo.png'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Navigation() {


    return (
        <div className='' >


            <Navbar collapseOnSelect expand="lg" className="" style={{ maxWidth: '100vw' }}>
                <Container className="nav" style={{ maxWidth: '100vw' }}>


                    <Navbar.Brand style={{ maxWidth: '100vw', marginLeft: '1rem' }}>
                        <Link to='/' className=' site-title '>

                            {/* logo */}
                            <img className='logo' src={logo} style={{ height: '100%', width: '7rem' }} alt='Jasmine Rhose Foundation logo'>
                            </img>
                            <div>
                                <div className='JRF-name' style={{ color: 'white', fontSize: '1.5rem' }} alt='Jasmine Rhose Foundation name' > &nbsp; Jasmine Rhose </div>
                                <div className='foundationName' style={{ color: 'white', fontSize: '1.5rem', marginTop: '-1.5rem' }}> FOUNDATION</div>
                            </div>

                        </Link>
                        {/* <Link className=" nav JRF-name" to="/">Jasmine Rhose Foundation</Link> */}
                    </Navbar.Brand>


                    <Navbar.Toggle aria-controls="basic-navbar-nav" sticky='top' className='menuTxt mx-auto mb-2' alt='menu'><u>MENU</u></Navbar.Toggle>

                    <Container style={{ maxWidth: '100vw' }}>
                        <Navbar.Collapse id="basic-navbar-nav "  >
                            <Nav className="ms-auto justify-content-end navbarSupportedContent " >

                                <Nav.Link as={Link} eventKey="1">
                                    <CustomLink className="nav-link" aria-current="page" to='/'>Home</CustomLink>
                                </Nav.Link>
                                <NavDropdown title="About Us" id="basic-nav-dropdown">
                                    <NavDropdown.Item>
                                        <Nav.Link as={Link} eventKey="2" >

                                            <CustomLink className="dropdown-item" to='/about'>What We Do</CustomLink>
                                        </Nav.Link>

                                        <NavDropdown.Divider />
                                    </NavDropdown.Item>
                                    <Nav.Link as={Link} eventKey="3" >
                                        <CustomLink className="dropdown-item" to='/story'>Our Story</CustomLink>
                                    </Nav.Link>

                                    <NavDropdown.Item>

                                    </NavDropdown.Item>
                                </NavDropdown>
                                {/* <Nav.Link as={Link} eventKey="7">
                                    <CustomLink className="nav-link" aria-current="page" to='/vision-dinner'>Vision Dinner</CustomLink>
                                </Nav.Link> */}

                                <Nav.Link as={Link} eventKey="4">
                                    <CustomLink className="nav-link" aria-current="page" to='/gallery'>Gallery</CustomLink>
                                </Nav.Link>

                                <Nav.Link as={Link} eventKey="5">
                                    <CustomLink className="nav-link" aria-current="page" to='/contact'>Contact Us</CustomLink>
                                </Nav.Link>

                                {/* <Nav.Link as={Link} eventKey="8">
                                    <CustomLink className="nav-link " aria-current="page" to='/schoolfund'>
                                        <button  style={{ backgroundColor:'rgba(112,207,241,255)', color: 'rgba(0, 0, 0)',borderColor:'white' }}>
                                            School Fund
                                        </button>
                                    </CustomLink>
                                </Nav.Link> */}

                                <Nav.Link as={Link} eventKey="6">
                                    <CustomLink className="nav-link" aria-current="page" to='/donate'>
                                        <button style={{ backgroundColor: 'rgba(255, 99, 71, 1)', color: 'white', borderColor:'white' }}>
                                            Give Now
                                        </button>
                                    </CustomLink>
                                </Nav.Link>



                                        {/* <button style={{ backgroundColor: 'rgba(255, 99, 71, 1)', color: 'white', borderColor:'white' }}>
                                           <a href='https://crm.bloomerang.co/HostedDonation?ApiKey=pub_956b8187-61f4-11ee-8019-0a4cd6191fc9&WidgetId=1770496'> Give Now
                                            </a>
                                        </button> */}
                               
                        

                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Container>
            </Navbar>


        </div >

    );
};

// $('nav select').change(function() {
//     window.location = $(this).find('option:selected').val();
//   });

// costumized link
function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
    return (
        <li className={isActive ? 'active' : ''} style={{ marginRight: '1rem' }}>
            <Link to={to}>{children}</Link>
        </li>
    )
}





export default Navigation;