import './Story.scss';
import { Link } from 'react-router-dom';



export default function Story() {
    return (<div>
        <div className='container mvBox' style={{ paddingBottom: '60px' }}>

            <div className='mission'>
                <h2 className='subheader mission'>
                    How It All Began...
                </h2>
                <p className='fontFormat'>
                    Growing up in the Philippines, Rhose witnessed the poverty and neglect of the defenseless. In 2006, she received a prophesy of having more children with a vivid picture of her in a garden caring for them.
                    She dismissed it, assuming it meant giving birth to more babies. At the time, it was thought to be out of the question. Then, in October of 2011, Rhose had a vision of an orphanage.
                    Remembering that original Word from God, the Jasmine Rhose Foundation began to take shape.
                </p>
                <p className='fontFormat'>
                    After praying with her husband, Scott, on how to move forward, Rhose decided to quit her full-time job so they could be fully committed to this work.
                </p>
                <p>
                    <i className='storyVerse'> 
                        "Being confident of this very thing, that He who has begun a good work in you will complete it until the day of Jesus Christ." Philippians 1:6
                    </i>
                </p>
                <p className='fontFormat'>
                    God is faithful! In October of 2017, the Jasmine Rhose Foundation officially started to rescue children from broken situations. They are being pulled out of abandonment, negligence, abuse, malnutrition, and even trafficking.
                    Some come from living on the street in a cardboard box. They now have food, shelter, protection, education, and a loving, intimate home environment. It all takes time, but the children no doubt continue to get healthier and happier as they are loved and cared for,
                    the way it was intended.
                </p>




                <br />
                <button className='supportBtn'>
                    <Link to='/donate' style={{ color: 'white', textDecoration: 'none' }}>Be part of our story...</Link>
                </button>
            </div>



        </div>
    </div>

    )
        ;
}