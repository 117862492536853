import './Contact.scss'
import { useRef } from 'react';
import { send } from '@emailjs/browser';

export default function Contact() {


    const nameInputRef = useRef();
    const emailInputRef = useRef();
    const subjectInputRef = useRef();
    const messageInputRef = useRef();

    function submitHandler(event) {
        event.preventDefault();
        //reading value useRef
        const enteredName = nameInputRef.current.value;
        const enteredEmail = emailInputRef.current.value;
        const enteredMessage = messageInputRef.current.value;
        const enteredSubject = subjectInputRef.current.value;
        const submitBtn = document.getElementById('send');
        submitBtn.innerHTML = 'MESSAGE SENT!'

        //constants for emailJS
        const serviceID = process.env.REACT_APP_SERVICE_ID;
        const templateID = process.env.REACT_APP_TEMPLATE_ID;
        const myNummer = process.env.REACT_APP_MY_NUMBER;


        const templateParams = {
            from_name: enteredName,
            email: enteredEmail,
            subject: enteredSubject,
            message: enteredMessage,
        };

        send(serviceID, templateID, templateParams, myNummer);

        event.target.reset();
        setInterval(() =>
            submitBtn.innerHTML = 'Send', 3000);


    }

    return (

        <div style={{ margin: 'auto', paddingBottom: '60px', paddingTop: '5%' }}>


            <h3 className='subheader contact'>
                For any comments and queries...
            </h3>
            <div className='container contactForm'>
                <form  onSubmit={submitHandler} style={{ padding: '15px' }}>

                    <div className='form-group row' >

                        <label className='col-sm-2 col-form-label' htmlFor='name'>
                            Name
                        </label>
                        <div className='col-sm-8'>
                            <input type='text' required id='name' ref={nameInputRef} placeholder='Name required' style={{ fontStyle: 'italic' }} />
                        </div>

                    </div>
                    <div className='  form-group row'>
                        <label className='col-sm-2 col-form-label' htmlFor='email'  >Email</label>

                        <div className='col-sm-8'>
                            <input type='email' required id='email' ref={emailInputRef} placeholder='Email required' style={{ fontStyle: 'italic' }} />
                        </div>

                    </div>
                    <div className='  form-group row'>
                        <label className='col-sm-2 col-form-label' htmlFor='subject'  >Subject</label>

                        <div className='col-sm-8'>
                            <input type='subject' id='subject' ref={subjectInputRef} placeholder='Subject' style={{ fontStyle: 'italic' }} />
                        </div>

                    </div>
                    <div className='form-group row'>
                        <label className='col-sm-2 col-form-label' htmlFor='message'>Message</label>
                        <div className='col-sm-8'>
                            <textarea type='text' required id='message' rows='7' ref={messageInputRef} placeholder='Message required' style={{ fontStyle: 'italic' }} />
                        </div>
                    </div>
                    <div className=' text-center'>
                        <br />
                        <button id='send'>Share my thoughts...</button>
                    </div>

                </form>
            </div>

        </div>


    )
        ;
}