import './About.scss';
import { Link } from 'react-router-dom';
import house from '../../assets/images/meal.jpg';
import worship from '../../assets/images/worship.jpg';
import play from '../../assets/images/waterfun.jpg';
import education from '../../assets/images/education.jpg';

export default function About() {
    return (<div>
        <div className='container mvBox' style={{ paddingBottom: '60px' }}>
            <div className='container '>
                <div className='mission '>
                    <h2 className='subheader mission'>
                        Mission
                    </h2>
                    <p className='fontFormat'>
                        We are committed to physically and spiritually nourish abandoned, neglected, abused, and orphaned children; to
                        demonstrate love and bring hope with the intention of them becoming a positive impact on their environment.
                    </p>

                </div>

                <div className='vision'>
                    <h2 className='subheader vision'>
                        Vision
                    </h2>
                    <p className='fontFormat'>
                        Seeing the abandoned, neglected and abused children in a loving environment and a nurturing place to
                        call home for their protection, guidance and security. These children will be prepared for life in their
                        community as valuable, contributing members who demonstrate strong Christian values.
                    </p>
                    {/* <p className='fontFormat'>
                Provide the abandoned, neglected and tribal children with a loving environment and a nurturing place to call home for their protection, guidance and security. These children will be prepared for life in their community as valuable, contributing members who demonstrate strong Christian values.
            </p> */}
                    <p className='fontFormat'>
                        To produce Christ-like disciples that bear the enduring fruit that Jesus spoke of in John 15.
                    </p>
                    <br/>
                </div>
            </div>


            <div className='servicesProvided'>
                <h2 className='subheader services'>
                    Services We Provide
                </h2>
                <div className='servicesContainer' style={{ paddingTop: '1rem' }}>
                    <div className='card card-about' style={{ width: '18rem', margin: 'auto', marginTop: '1rem' }}>

                        <img src={house} className="card-img-top" alt="children around a table sharing a meal" />
                        <div className='card-body'>
                            <div className='card-title' >

                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" className="bi bi-house-heart-fill" viewBox="0 0 16 16">
                                <path d="M7.293 1.5a1 1 0 0 1 1.414 0L11 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l2.354 2.353a.5.5 0 0 1-.708.707L8 2.207 1.354 8.853a.5.5 0 1 1-.708-.707L7.293 1.5Z" />
                                <path d="m14 9.293-6-6-6 6V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9.293Zm-6-.811c1.664-1.673 5.825 1.254 0 5.018-5.825-3.764-1.664-6.691 0-5.018Z" />
                                </svg> */}
                                <h4 style={{ paddingTop: '.5rem', paddingBottom: '.75rem' }}> Residential Housing</h4>

                            </div>

                            Our goal is to foster a healthy family structure. In this nurturing environment, we prioritize and foster strong
                            connections between houseparents and children, similar to family-like bond. We keep it small. Our intention is to
                            maintain it like that to provide an excellent quality of care.
                        </div>

                    </div>

                    <div className='card card-about' style={{ width: '18rem', margin: 'auto', marginTop: '1rem' }}>
                        <img src={worship} className="card-img-top" alt="kids and adults in a circle singing worship accompanied by a guitar" />


                        <div className='card-body'>
                            <div className='card-title' >

                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-flower2" viewBox="0 0 16 16">
                                <path d="M8 16a4 4 0 0 0 4-4 4 4 0 0 0 0-8 4 4 0 0 0-8 0 4 4 0 1 0 0 8 4 4 0 0 0 4 4zm3-12c0 .073-.01.155-.03.247-.544.241-1.091.638-1.598 1.084A2.987 2.987 0 0 0 8 5c-.494 0-.96.12-1.372.331-.507-.446-1.054-.843-1.597-1.084A1.117 1.117 0 0 1 5 4a3 3 0 0 1 6 0zm-.812 6.052A2.99 2.99 0 0 0 11 8a2.99 2.99 0 0 0-.812-2.052c.215-.18.432-.346.647-.487C11.34 5.131 11.732 5 12 5a3 3 0 1 1 0 6c-.268 0-.66-.13-1.165-.461a6.833 6.833 0 0 1-.647-.487zm-3.56.617a3.001 3.001 0 0 0 2.744 0c.507.446 1.054.842 1.598 1.084.02.091.03.174.03.247a3 3 0 1 1-6 0c0-.073.01-.155.03-.247.544-.242 1.091-.638 1.598-1.084zm-.816-4.721A2.99 2.99 0 0 0 5 8c0 .794.308 1.516.812 2.052a6.83 6.83 0 0 1-.647.487C4.66 10.869 4.268 11 4 11a3 3 0 0 1 0-6c.268 0 .66.13 1.165.461.215.141.432.306.647.487zM8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                </svg> */}

                                <h4 style={{ paddingTop: '.5rem', paddingBottom: '.75rem' }}> Spiritual Opportunities</h4>
                            </div>

                            Our houseparents on duty and our children attend Sunday worship services as a family.  The teens are also offered
                            opportunities to participate in youth group activities through local church connections.
                        </div>

                    </div>

                    <div className='card card-about' style={{ width: '18rem', margin: 'auto', marginTop: '1rem' }}>
                        <img src={play} className="card-img-top" alt="children playing together with water and a toy" />
                        <div className='card-body'>

                            <div className='card-title'>

                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-person-hearts" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566ZM9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z" />
                                </svg> */}

                                <h4 style={{ paddingTop: '.5rem', paddingBottom: '.75rem' }}> Therapeutic Recreation</h4>
                            </div>
                            We use fun activities like sports, art, and outdoor adventures to foster the development of self-confidence and
                            healthy peer relationships. We celebrate special occasions like birthdays, and special holidays. Our goal is to have a
                            big family outing every other month.
                        </div>

                    </div>
                    <div className='card card-about' style={{ width: '18rem', margin: 'auto', marginTop: '1rem' }}>
                        <img src={education} className="card-img-top" alt="kids being homeschooled" />

                        <div className='card-body'>
                            <div className='card-title'>

                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                                    <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                                </svg> */}

                                <h4 style={{ paddingTop: '.5rem', paddingBottom: '.75rem' }}> Education</h4>
                            </div>
                            Most of the children we rescue have none or too little educational background. JRH offers specialized private
                            education and provide ongoing tutoring to improve academic performance.
                        </div>

                    </div>

                </div>
                <br />
                <button className='supportBtn' style={{ width: 'fit-content' }}>
                    <Link to='/donate' style={{ color: 'white', textDecoration: 'none' }}>Partner with us...</Link>
                </button>
            </div>



        </div>
    </div>

    )
        ;
}