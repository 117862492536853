import "./Footer.scss";

export default function Footer() {

    return (
        <footer className='footerFormat'>
            <div className='footerFormat'>
                <p>
                    Copyright &copy; 2023 Jasmine Rhose Foundation
                </p>
            </div>
        </footer>
    )

}