import './ThankYou.scss'
import Logo from '../../assets/images/iconlogo.png';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';

import Iframe from 'react-iframe'

import React, { useEffect } from 'react';

import useExternalScripts from '../../helpers/helpers'


export default function Donate() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);




    return (
        <div>
            <div className='container font-format ' style={{ margin: 'auto', paddingTop: '5vh' }}>

                <h1 className='givingTitle'>Thank You!</h1>

                <div className='givingText'>
                    <br />
                    <p>
                        Your generosity is changing lives. We truly appreciate your continued support towards Jasmine Rhose Foundation.
<br></br>
<br></br>
                        Together we can "Defend the poor and the fatherless; do justice to the afflicted and needy." Psalm 82:3
                    </p>

                     

                        

                    <br />
                    <br />


            


                </div>
           
            </div>
        </div>

    );
}