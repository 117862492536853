import './Donate.scss'
// import paypal from '../../assets/images/paypal-btn.png';
import venmo from '../../assets/images/venmo.png';
import Logo from '../../assets/images/iconlogo.png';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';

import Iframe from 'react-iframe'

import React, { useEffect } from 'react';

import useExternalScripts from '../../helpers/helpers'


export default function Donate() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const initialOptions = {
        clientId: "ZJAQSA8XWVJ4J",
        currency: "USD",
        intent: "capture",
    };




    return (
        <div>
            <div className='container font-format ' style={{ margin: 'auto', paddingTop: '5vh' }}>

                <h1 className='givingTitle'>Your Giving Changes Lives</h1>

                <div className='givingText'>
                    <br />
                    <p>
                        If you would like to partner with us in prayer or finances, please let us know.
                    </p>

                    <p>
                        To financially support the ministry of JRF, you can do any of the following:
                        <br />
                        <br />
                        <h3>Checks</h3>

                        Please make your checks
                        payable to <u>“Jasmine Rhose Foundation”</u> and mail to
                    </p>
                    <div className='address' style={{ color: 'rgba(255, 99, 71, 1)' }}>
                        P.O. Box 1282 <br />
                        Streamwood, IL 60107 <br />
                    </div>

                    <br />
                    <br />

                    <h3>Online Donation</h3>
                    <br />
                    You can donate online using Zelle, Venmo, Credit/Debit or Check.
                    <br></br>Do you have a donor-advised fund? You can give online using Givebutter.

                    <div className='donateContainer' style={{ paddingTop: '1rem' }}>


                        {/* ZELLE */}

                        <div className='card card-donate' style={{ width: '18rem', margin: 'auto', marginTop: '1rem' }}>

                            <div className='card-body'>
                                <div className='card-title' >
                                    <h4 style={{ paddingTop: '.5rem', paddingBottom: '.75rem', color: '#3f8f24' }}>Zelle</h4>

                                </div>

                                <mark>With $0 transaction fee</mark>, donate using your online banking via Zelle using this email:

                                <br />
                                <br />
                                <p style={{ backgroundColor: '#6c1cd3', color: 'white' }}>
                                    give@jasminerhose.org
                                </p>



                            </div>
                        </div>

                        {/* VENMO */}

                        <div className='card card-donate' style={{ width: '18rem', margin: 'auto', marginTop: '1rem' }}>

                            <div className='card-body'>
                                <div className='card-title' >
                                    <h4 className='address' style={{ paddingTop: '.5rem', paddingBottom: '.75rem', color: '#3f8f24' }}>Venmo</h4>

                                </div>
                                Donate with your online banking via Venmo.
                                <br />
                                <br />
                                <br />
                                <p>
                                    <a href="https://www.venmo.com/u/jrfoct2011" >
                                        <img src={venmo} style={{ width: '80%' }} alt='venmo button'></img>
                                    </a>

                                </p>


                            </div>
                        </div>

                        {/* PAYPAL */}
                        {/* <div className='card card-donate' style={{ width: '18rem', margin: 'auto', marginTop: '1rem' }}>

                            <div className='card-body'>
                                <div className='card-title' >
                                    <h4 className='address' style={{ paddingTop: '.5rem', paddingBottom: '.75rem', color: 'rgba(255, 99, 71, 1)' }}> Paypal</h4>

                                </div>

                                Donate with your debit card, credit card or your paypal account using this option.
                                <br />
                                <br />
                                <p>
                                    <Button className='shadow' style={{ backgroundColor: '#FFCC00', width: '90%', border: 'none' }}>
                                        <a href="https://www.paypal.com/donate/?hosted_button_id=5NWNYDD32YYUS&enable-funding=venmo&source=url" >
                                            <img src={paypal} style={{ width: '70%' }} alt='paypal button'></img>
                                        </a>
                                    </Button>
                                </p>

                            </div>
                        </div> */}

                        {/* Bloomerang */}
                        <div className='card card-donate' style={{ width: '18rem', margin: 'auto', marginTop: '1rem' }}>

                            <div className='card-body'>
                                <div className='card-title' >
                                    <h4 className='address' style={{ paddingTop: '.5rem', paddingBottom: '.75rem', color: '#3f8f24' }}> Credit/Debit or Check</h4>

                                </div>

                                Donate with your debit card or credit card  or check online using this option.
                                <br />
                                <p>

                                    <br />

                                    <Button className='zeffy-btn shadow-lg  rounded"' style={{ backgroundColor: 'rgba(255, 99, 71, 1)', borderColor: 'white', boxShadow: '10px 5px 5px black' }} onClick={handleShow1}>
                                        Donate
                                    </Button>
                                    <Modal show={show1} onHide={handleClose1}>
                                        <Modal.Header closeButton>
                                            <img src={Logo} style={{ width: '7rem', margin: '1px' }}></img>

                                            <Modal.Title className='donateHeader' style={{ marginLeft: '20px', fontSize: '3rem' }}>Partner with Us</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div style={{ position: 'relative', overflow: 'hidden', width: '100%', height: '1200px', paddingTop: '100%' }}>
                                                {/* <iframe title='Jasmine Rhose' style={{ position: 'absolute', border: '0', top: '0', left: '0', bottom: '0', right: '0', width: '100%', height: '100%' }} src='https://crm.bloomerang.co/HostedDonation?ApiKey=pub_956b8187-61f4-11ee-8019-0a4cd6191fc9&WidgetId=54272' >
                                                </iframe></div> */}
                                                {/* VISION DINNER IFRAME */}
                                                <iframe title='Jasmine Rhose' style={{ position: 'absolute', border: '0', top: '0', left: '0', bottom: '0', right: '0', width: '100%', height: '100%' }} src='https://crm.bloomerang.co/HostedDonation?ApiKey=pub_956b8187-61f4-11ee-8019-0a4cd6191fc9&WidgetId=1770496' >
                                                </iframe></div>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose1}>
                                                Close
                                            </Button>

                                        </Modal.Footer>


                                    </Modal>

                                </p>

                            </div>
                        </div>

                    </div>

                    <div className='givingText'>
                        <br />
                        <br />
                        <h3>For Donor-Advised Funds</h3>


                        {/* Givebutter*/}
                        <div className='card card-donate' style={{ width: '18rem', margin: 'auto', marginTop: '1rem' }}>

                            <div className='card-body'>
                                <div className='card-title' >
                                    <h4 className='address' style={{ paddingTop: '.5rem', paddingBottom: '.75rem', color: '#3f8f24' }}> Givebutter</h4>

                                </div>

                                Donate with your donor-advised fund.
                                <br />
                                <br />
                                <p>

                                    <Button className='zeffy-btn shadow-lg' onClick={handleShow} style={{ borderColor: 'white', color: 'white' }}>
                                        Givebutter
                                    </Button>
                                    <Modal show={show} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            <img src={Logo} style={{ width: '7rem', margin: '1px' }}></img>
                                            <Modal.Title className='donateHeader' style={{ marginLeft: '20px', fontSize: '3rem' }}>Givebutter</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <givebutter-widget id="LyJlXg"></givebutter-widget>

                                            {/* <Iframe url="https://www.paypal.com/donate/?hosted_button_id=Z53WFR7RPWHBJ"
                                width="640px"
                                height="320px"
                                display="block"
                                position="relative"
                                sameSiteCookies="none"
                            /> */}

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>

                                        </Modal.Footer>


                                    </Modal>
                                </p>

                            </div>
                        </div>
                    </div>



                    {/* <givebutter-widget id="pAd7Og"></givebutter-widget> */}



                    <br />

                    <p>
                        Jasmine Rhose Foundation is a registered 501(c)(3) nonprofit organization.
                    </p>
                    <p>
                        All donations are tax-deductible to the fullest extent of the law.

                    </p>
                    <p>
                        Federal Tax ID:45-5342661
                    </p>


                </div>
                <div>

                </div>
            </div>
        </div>

    );
}