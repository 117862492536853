import React from 'react';
// import hero from '../../assets/images/header-smaller-combined.jpeg'
import './HomePage.scss'
import Vid from '../../assets/movies/JRF.mp4'
import { Link } from 'react-router-dom';


function HomePage() {

    return (
        <div>
            <div >
                <div className='vidBox'>
                    <video src={Vid} muted loop autoPlay style={{ height: '100vh' }}></video>
                </div>
                <div className='verse' >
                    <div className='verseContainer'>
                        <span style={{ backgroundColor: 'rgba(108, 140, 160, 0.75)', borderRadius: '10px' }}>
                            "Defend the poor and the fatherless; do justice
                            to the afflicted and needy." Psalm 82:3..
                            <br />
                        </span>
                        <button className='learnMoreBtn'>&nbsp;
                            <Link to='/about' style={{ color: 'white' }}>Find out more...</Link>
                            &nbsp;
                        </button>
                        {/* <button className='learnMoreBtn'>&nbsp;
                            <Link to='/vision-dinner' style={{ color: 'white'}}>Register for Vision Dinner</Link>
                            &nbsp;
                        </button> */}
                    </div>
                </div>

            </div>

        </div>
    );
}

export default HomePage;