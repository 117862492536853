


import './App.css';
import React from 'react';
import { Routes, Route } from "react-router-dom";

// import pages
import Home from './components/pages/HomePage'
import About from './components/pages/About.js'
import Story from './components/pages/Story';
import Donate from './components/pages/Donate.js';
import Gallery from './components/pages/Gallery';
import Contact from './components/pages/Contact';
import Vision from './components/pages/Vision';
import Thanks from './components/pages/ThankYou.js';
//Holiday Giving
// import GivingTuesday from './components/pages/Giving_Tuesday_Christmas';
// import SchoolFund from './components/pages/SchoolFund.js';
//import components
import Footer from './components/Footer.js';
import Navigation from './components/Navigation';
//import header img
// import hero from './assets/images/kidsonbeach2.jpg'


function App() {
  // let Component;
  // switch (window.location.pathname) {
  //   case '/':
  //     Component = Home 
  //     break
  //   case "/about":
  //     Component = About 
  //     break
  //   case '/donate':
  //     Component = Donate 
  //     break
  //   default:
  //     Component = Home 
  //     break}

  return (

    <div className='flexColumn'>

      <Navigation></Navigation>
      {/* <div className='hero-wrapper'>
        <img className ='hero'src={hero} alt='jrf header'></img>
        <div className='verse'>
              "Defend the poor and the fatherless; do justice
              <br/> to the afflicted and needy." Psalm 82:3..
        </div>
      </div> */}

      <div className='wrapper center'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/story' element={<Story />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/donate' element={<Donate />} />
          <Route path='/contact' element={<Contact />} />
          {/* Holliday Giving tab */}
          {/* <Route path='/schoolfund' element={<SchoolFund />} /> */}
          {/* <Route path='/vision-dinner' element={<Vision />} /> */}
          <Route path='/thankyou' element={<Thanks />} />
        </Routes>
      </div>
      <Footer></Footer>


    </div>


  );
}

export default App;
