import './Gallery.scss'

//import pictures

import construction1 from '../../assets/images/construction_1.jpg';
import construction2 from '../../assets/images/construction_2.jpg';
import construction3 from '../../assets/images/construction_3.jpg';
import construction4 from '../../assets/images/construction_4.jpg';
import construction5 from '../../assets/images/construction_5.jpg';
import construction6 from '../../assets/images/construction_6.jpg';
import construction7 from '../../assets/images/construction_7.jpg';
import construction8 from '../../assets/images/construction_8.jpg';
import construction9 from '../../assets/images/construction_9.jpg';
import construction10 from '../../assets/images/construction_10.jpg';
import construction11 from '../../assets/images/construction_11.jpg';
import construction12 from '../../assets/images/construction_12.jpg';
import construction13 from '../../assets/images/construction_13.jpg';
import construction14 from '../../assets/images/construction_14.jpg';
import kids from '../../assets/images/kids.jpeg';
import kids1 from '../../assets/images/Kids1.jpeg';
import slide3 from '../../assets/images/stone.jpeg';
import costume from '../../assets/images/costume.jpg';
import prayer from '../../assets/images/prayer.jpg';
import school from '../../assets/images/education2.jpg';
import play from '../../assets/images/education3.jpg';
import kitchen from '../../assets/images/kitchen.JPG';
import hug from '../../assets/images/hug.JPG';
import swing from '../../assets/images/swing.JPG';




export default function Gallery() {
    return (<div>
        <div className='' style={{ paddingTop: '30px' }}>
            <h2 className='subheader'>
                Gallery
            </h2>
            <div className='galleryBox'>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={construction1} className="card-img-top" alt="construction1" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Construction beginnings...</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={construction2} className="card-img-top" alt="construction2" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Construction progress part 1.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={construction3} className="card-img-top" alt="construction3" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Construction progress part 3.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={construction4} className="card-img-top" alt="construction4" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Construction progress part 4.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={construction5} className="card-img-top" alt="construction5" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Construction progress part 5.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={construction6} className="card-img-top" alt="construction6" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Construction progress part 6.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={construction7} className="card-img-top" alt="construction7" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Construction progress part 7.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={construction8} className="card-img-top" alt="construction8" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Construction progress part 8.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={construction9} className="card-img-top" alt="construction9" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Construction progress part 9.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={construction10} className="card-img-top" alt="construction10" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Construction progress part 10.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={construction11} className="card-img-top" alt="construction11" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Construction progress part 11.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={construction12} className="card-img-top" alt="construction12" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Construction progress part 12.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={construction13} className="card-img-top" alt="construction13" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Construction progress part 13.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={construction14} className="card-img-top" alt="construction14" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Construction progress part 14.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={school} className="card-img-top" alt="homeschool" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Learning through tailored homeschool education.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={hug} className="card-img-top" alt="a warm embrace" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Comfort and protection.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={kitchen} className="card-img-top" alt="kids learning how to do kitchen work" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Children being equipped with life skills.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={prayer} className="card-img-top" alt="kids praying for their friend" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Praying with Jasmine.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={kids1} className="card-img-top" alt="kids playing in the park" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Having fun in the park.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={costume} className="card-img-top" alt="kids wearing costumes" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Costume party.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={kids} className="card-img-top" alt="kids praying with caregiver" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Children praying with their caregiver.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={play} className="card-img-top" alt="kids wearing costumes" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Enjoying healthy grown-up relationships.</p>
                        <p className="card-text"></p>
                    </div>
                </div>

                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={swing} className="card-img-top" alt="kids enjoying a swing" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Filling leisure time with laughter and play.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
                <div className="card gallery-card border-light shadow-lg p-3 mb-5 bg-body rounded" style={{ width: "25rem" }}>
                    <img src={slide3} className="card-img-top" alt="completed wall project" />
                    <div className="card-body">
                        <p className="card-title gallery-title" style={{ fontSize: '2rem' }}>Completed wall project through the Lord's provision.</p>
                        <p className="card-text"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
        ;
}